import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import wrapperLayout from '@/components/Layout';

const NotFoundPage = () => {
  const [time, setTime] = useState<number>(3);
  const router = useRouter();
  useEffect(() => {
    let timer: any = null;
    if (time > 1) {
      timer = setTimeout(() => {
        setTime((time) => {
          return time - 1;
        });
      }, 1000);
    } else {
      router.replace('/');
    }
    return () => {
      clearTimeout(timer);
    };
  }, [time]);
  return (
    <div className="textin-404">
      <div className="textin-404-card">
        <div className="img-wrapper">
          <Image width={88} height={92} src={'/icons/pic_non_textin@2x.png'} alt="" />
        </div>
        <div className="title">您访问的页面不存在</div>
        <div className="desc">
          <a>{time}</a> 秒后跳转TextIn首页
        </div>
      </div>

      <style jsx>{`
        .textin-404 {
          width: 100%;
          padding: 20px 0;
          background: #f7f7fb;
        }
        .textin-404-card {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 1200px;
          height: 514px;
          margin: 0px auto;
          background: #ffffff;
          border-radius: 2px;
          box-shadow: 0px 2px 10px 0px rgba(167, 183, 219, 0.2);
        }
        .img-wrapper {
          margin-bottom: 16px;
        }
        .title {
          color: #3a415c;
          font-weight: 600;
          font-size: 14px;
          text-align: center;
        }
        .desc {
          color: #868a9c;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
        }
      `}</style>
    </div>
  );
};

NotFoundPage.Layout = wrapperLayout();

export default NotFoundPage;
